var isMobile = mobileAndTabletcheck();
var scrollTop = 0;

var currentStep = 1;
var controller = $(".controller");

var current_question = 1;
var selectedchoice = -1;
var isMulti = false;

var needExitConfirm = false;

function stepHandle(result){
	switch(currentStep){
		case 1:
			break;
		case 2:
			loadQuestion(current_question);
			needExitConfirm = true;
			break;
		case 4:
		case 5:
		case 6:
		case 7:
		case 8:
		case 9:
		case 10:
		case 11:
			// question part
    		controller.append(result);
    		break;
    	case 12:
    		// result page
    		controller.append(result);
    		resultPageInit();
    		break;
    	case 13:
    		// survey page
    		controller.append(result);
    		suveryPageInit();
    		break;
    	case 14:
    		// thankyou page
    		controller.append(result);
    		break;
		default:
			break;
	}
}

function nextStepAnim(){
	switch(currentStep){
		case 2:
			// homepage
			$({})
			.queue(function (next) {
		        $("section.homepage .inner-content").fadeTo(400, 0, next)
		    })
		    .queue(function (next) {
		    	$("section.personal-info").css({
		    		'visibility': 'visible',
		    		'z-index': 5
		    	});
		        $("section.homepage .content").animate({
					'height': "100%"
				}, 750, next)
		    })
		    .queue(function (next) {
		    	$("section.personal-info").show();
		        $("section.homepage").animate({
					'bottom': "100%"
				}, 750, next)
		    })
		    .queue(function (next) {
		    	$("section.homepage").hide().css({
		    		'visibility': 'hidden',
		    	})
		    })
		    break;
		case 3:
			// personal info
			$({})
			.queue(function (next) {
				$("html, body").stop().animate({scrollTop:0}, 400, "easeInOutQuint");
		        $("section.personal-info .inner-content").fadeTo(400, 0, next);
		    })
		    .queue(function (next) {
		    	$("section.question-part[data-question='1']").show().css({
		    		'visibility': 'visible',
		    		'z-index': 3
		    	});
		        $("section.personal-info .content-container").animate({
					'padding-top': 0
				}, 750, "easeInOutQuint", next)
		    })
		    .queue(function (next) {
		        $("section.personal-info").animate({
					'bottom': "100%"
				}, 750, "easeInOutQuint", next)
		    })
		    .queue(function (next) {
		    	$("section.question-part[data-question='1']").css({
		    		'z-index': 5
		    	})
		    	$("section.personal-info").hide().css({
		    		'visibility': 'hidden',
		    		'z-index': '-1',
		    	})
		    })
			break;
		case 4:
		case 5:
		case 6:
		case 7:
		case 8:
		case 9:
		case 10:
		case 11:
			// question part
			var elem = $("section.question-part[data-question='" + current_question + "']");
			$({})
			.queue(function (next) {
				$("html, body").stop().animate({scrollTop:0}, 400, "easeInOutQuint");
		        $(".inner-content", elem).fadeTo(400, 0, next)
		    })
		    .queue(function (next) {
		    	elem.next().show().css({
		    		'visibility': 'visible',
		    		'z-index': 3
		    	});
		        $(".content-container", elem).animate({
					'padding-top': 0
				}, 750, "easeInOutQuint", next)
		    })
		    .queue(function (next) {
		        elem.animate({
					'bottom': "100%"
				}, 750, "easeInOutQuint", next)
		    })
		    .queue(function (next) {
		    	elem.next().css({
		    		'z-index': 5
		    	})
		    	elem.hide().css({
		    		'visibility': 'hidden',
		    		'z-index': '-1',
		    	})
		    })
		    current_question++;
			break;
		// case 12:
		// 	// result page
		// 	var elem = $("section.question-part[data-question='" + current_question + "']");
		// 	var mainframe = $("section.result-panel .mainframe");
		// 	var productCol = $(".product-col > div", mainframe);
		// 	var txtCol = $(".text-col > div", mainframe);
		// 	$({})
		// 	.queue(function (next) {
		// 		$("html, body").stop().animate({scrollTop:0}, 400, "easeInOutQuint");
		// 		mainframe.css({
		// 			"transform": "translateX(-100%)"
		// 		})
		// 		productCol.css('opacity', 0);
		// 		txtCol.css('opacity', 0);
		//         $(".inner-content", elem).fadeTo(400, 0, next);
		//     })
		//     .queue(function (next) {
		//     	elem.next().css({
		//     		'visibility': 'visible',
		//     		'z-index': 3
		//     	});
		//         $(".content-container", elem).animate({
		// 			'padding-top': 0
		// 		}, 750, "easeInOutQuint", next)
		//     })
		//     .queue(function (next) {
		//     	elem.next().css({
		//     		'z-index': 5
		//     	})
		//     	$({
		// 	        Counter: -100
		// 	    }).animate({
		// 	        Counter: 0
		// 	    }, {
		// 	        duration: 1000,
		// 	        easing: "easeInOutQuint",
		// 	        step: function() {
		// 	            mainframe.css("transform", "translateX(" + Math.round(this.Counter) + '%)');
		// 	        },
		// 	        complete: next
		// 	    })
		//     	elem.hide().css({
		//     		'visibility': 'hidden',
		//     		'z-index': '-1',
		//     	})
		//     })
		//     .queue(function (next) {
		//     	productCol.fadeTo(400, 1);
		//     	txtCol.fadeTo(400, 1);
		//     })
		// 	break;
		// case 13:
		// 	// survey page
		// 	var elem = $("section.result-panel");
		// 	var mainframe = $("section.result-panel .mainframe");
		// 	var productCol = $(".product-col > div", mainframe);
		// 	var txtCol = $(".text-col > div", mainframe);
		// 	var productBtn = $(".find-product", mainframe);
		// 	var nextElem = elem.next();
		// 	$({})
		// 	.queue(function (next) {
		// 		$("html, body").stop().animate({scrollTop:0}, 400, "easeInOutQuint");
		// 		productCol.fadeTo(400, 0);
		// 		txtCol.fadeTo(400, 0);
		// 		productBtn.delay(200).fadeTo(400, 0, next);
		//     })
		//     .queue(function (next) {
		//     	nextElem.css({
		//     		'visibility': 'visible',
		//     		'z-index': 5
		//     	});
		//     	nextElem.find(".banner").css({
		//     		"opacity": 0
		//     	})
		//     	nextElem.find(".content-container").css({
		//     		"top": $(window).height()
		//     	})
		//     	$(".text-col", mainframe).animate({
		//     		"width": 0
		//     	}, 750, "easeInOutQuint", next)
		//      //    $({
		// 	    //     Counter: 0
		// 	    // }).animate({
		// 	    //     Counter: 100
		// 	    // }, {
		// 	    //     duration: 1000,
		// 	    //     easing: "easeInOutQuint",
		// 	    //     step: function() {
		// 	    //         mainframe.css("transform", "translateX(" + Math.round(this.Counter) + '%)');
		// 	    //     },
		// 	    //     complete: next
		// 	    // })
		//     })
		//     .queue(function (next) {
		//     	nextElem.find(".content-container").animate({
		//     		"top": 0
		//     	}, 750, "easeInOutQuint", next);
		//     })
		//     .queue(function (next) {
		//     	nextElem.find(".banner").fadeTo(400, 1);
		//     	elem.hide().css({
		//     		'visibility': 'hidden',
		//     		'z-index': '-1',
		//     	}).hide();
		//     	mainframe.attr('style', '');
		//     	nextElem.removeClass("no-bg");
		//     })
		// 	break;
		case 12:
			// result page
			var elem = $("section.question-part[data-question='" + current_question + "']");
			var mainframe = $("section.result-panel2 .mainframe");
			$({})
			.queue(function (next) {
				$("html, body").stop().animate({scrollTop:0}, 400, "easeInOutQuint");
				mainframe.css('opacity', 0);
		        $(".inner-content", elem).fadeTo(400, 0, next);
		    })
		    .queue(function (next) {
		    	elem.next().css({
		    		'visibility': 'visible',
		    		'z-index': 3
		    	});
		        $(".content-container", elem).animate({
					'padding-top': 0
				}, 750, "easeInOutQuint", next)
		    })
		    .queue(function (next) {
		    	elem.next().css({
		    		'z-index': 5
		    	})
		    	mainframe.fadeTo(400, 1, next);
		    	elem.hide().css({
		    		'visibility': 'hidden',
		    		'z-index': '-1',
		    	})
		    })
			break;
		case 13:
			// survey page
			var elem = $("section.result-panel2");
			var mainframe = $("section.result-panel2 .mainframe");
			var nextElem = elem.next();
			$({})
			.queue(function (next) {
				$("html, body").stop().animate({scrollTop:0}, 400, "easeInOutQuint");
				nextElem.css({
		    		'visibility': 'visible',
		    		'z-index': 5
		    	});
		    	nextElem.find(".banner").css({
		    		"opacity": 0
		    	})
		    	nextElem.find(".content-container").css({
		    		"top": $(window).height()
		    	})
		    	mainframe.fadeTo(400, 0, next);
		    })
		    .queue(function (next) {
		    	nextElem.find(".content-container").animate({
		    		"top": 0
		    	}, 750, "easeInOutQuint", next);
		    })
		    .queue(function (next) {
		    	nextElem.find(".banner").fadeTo(400, 1);
		    	elem.hide().css({
		    		'visibility': 'hidden',
		    		'z-index': '-1',
		    	}).hide();
		    	mainframe.attr('style', '');
		    	nextElem.removeClass("no-bg");
		    })
			break;
		case 14:
			// thankyou page
			var elem = $("section.survey-part");
			var nextElem = elem.next();
			$({})
			.queue(function (next) {
				$("html, body").stop().animate({scrollTop:0}, 400, "easeInOutQuint");
		        $(".inner-content", elem).fadeTo(400, 0, next)
		    })
		    .queue(function (next) {
		    	elem.next().css({
		    		'visibility': 'visible',
		    		'z-index': 3
		    	});
		        $(".content-container", elem).animate({
					'padding-top': 0
				}, 750, "easeInOutQuint", next)
		    })
		    .queue(function (next) {
		        elem.animate({
					'bottom': "100%"
				}, 750, "easeInOutQuint", next)
		    })
		    .queue(function (next) {
		    	elem.next().css({
		    		'z-index': 5
		    	})
		    	elem.hide().css({
		    		'visibility': 'hidden',
		    		'z-index': '-1',
		    	})
		    })
			break;
		default:
			break;
	}
}

function loadQuestion(current_question, choice = 0) {
    $('body').request('Handle::onQuestion', {
        type: 'POST',
        data: {
            'q': current_question,
            'a': choice,
        },
        success: function(data) {
        	controller.append(data.result);
            $('.answer').removeClass('sel');
            $('.main_question').html(data.question);
            $('.answer').each(function() {
                if (data.answer[$(this).data('answer')]) {
                    $(this).html(data.answer[$(this).data('answer')]);
                    $(this).show();
                } else {
                    $(this).hide();
                }
            });
            selectedchoice = -1;
        },
        error: function(data) {
            $.oc.flashMsg({
                'text': 'Ajax error',
                'class': 'error',
                'interval': 3
            })
        }
    });
}

function resultPageInit(){
	var swiper = new Swiper('.pd-thumbnail.swiper-container',{
		effect: 'fade',
		fadeEffect: {
		    crossFade: true
		},
	});

	$(".product-list a").click(function(e){
		e.preventDefault();
		var index = $(this).parent().index();
		$(this).parent().addClass("active").siblings().removeClass("active");
		swiper.slideTo(index);
	})

	$("section.result-panel2 .next-btn").click(function(e){
		e.preventDefault();
		$.oc.stripeLoadIndicator.show();
		$('body').request('Handle::onGetSurvey', {
			type: 'POST',
			success: function(data) {
	        	currentStep++;
	        	$.oc.stripeLoadIndicator.hide();
			    stepHandle(data.result);
			    nextStepAnim();
	        }
		})
	})

	$(window).resize(function(){
		$("section.result-panel .mainframe").height("auto").height($("section.result-panel .mainframe-container").outerHeight());
	}).trigger("resize");
	
}

function suveryPageInit(){
	$(".pd-category .add-icon").click(function(e){
		e.preventDefault();
		$(this).closest(".pd-category").find(".rec-row-container").slideDown(400);
	})
	$(".pd-category .add-more").click(function(e){
		e.preventDefault();
		var container = $(this).closest(".pd-category");
		var rec = $(".rec-row:not(.template)", container);
		var elem = $(".rec-row.template", container).clone();
		elem.removeClass("template").find("input").each(function(index){
			var name = $(this).attr('name');
			name = name.replace("[index]", "[" + rec.length + "]");
			$(this).attr("name", name);
		});
		rec.last().after(elem);
	})
	$(".rec-row-container").on('click', '.remove-icon', function(){
		var r = confirm("Do you want to remove product?");
		if(r){
			$(this).parent().empty().hide();
		}
	})
}

$(function(){
	if(isMobile) $('body').addClass('isMobile');

	$("section.homepage .start-btn").click(function(e){
		e.preventDefault();
		currentStep++;
		// controller.addClass("step-personal");
		stepHandle();
		nextStepAnim();
	})

	$(".gender-radio").click(function(e){
		e.preventDefault();
		$(".gender-radio").removeClass("active");
		$(this).addClass('active').siblings('input').prop("checked", true);
	})

	$(".age-radio").click(function(e){
		e.preventDefault();
		$(".age-radio").removeClass("active");
		$(this).addClass('active').siblings('input').prop("checked", true);
	})

	controller.on("click", ".submit-btn", function(e){
		e.preventDefault();
		$(this).closest("form").submit();
	})

	controller.on("click", ".form-answer", function(e){
		var name = $(this).siblings("input").attr("name");
		var input = $(this).closest("form").find("input[name='" + name + "']");
		if(input.attr('type') == 'radio'){
			input.siblings().removeClass("active");
			$(this).addClass('active').siblings('input').prop("checked", true);
		}else if(input.attr('type') == 'checkbox'){
			var siblingInput = $(this).siblings("input");
			$(this).toggleClass('active');
			siblingInput.prop("checked", !siblingInput.prop("checked"));
		}
	})

	// $('.ui.dropdown').dropdown({

	// })
    
	$(window).resize(function(){
		controller.height($(window).height());
	}).trigger('resize');

	$(window).scroll(function(){
		scrollTop = $(window).scrollTop();
	}).trigger('scroll');
})

$(window).on('load', function() {
	$(".preload").removeClass("preload");
})

window.onbeforeunload = function() {
    if (needExitConfirm) return '';
};

$('#personal-info-form')
.on('ajaxSetup', function(data) {
    
})
.on('ajaxAlways', function() {
    
})
.on('ajaxSuccess', function(data) {
    currentStep++;
    stepHandle();
    nextStepAnim();
	// controller.addClass("step-question1");
})
.on('ajaxFail', function() {})

controller
.on('ajaxSuccess', "#answer-form", function(event, context, data, status, jqxhr) {
	currentStep++;
    stepHandle(data.result);
    nextStepAnim();
})

controller
.on('ajaxSuccess', "#survey-form", function(event, context, data, status, jqxhr) {
	currentStep++;
    stepHandle(data.result);
    nextStepAnim();
})